import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { API_WITHDRAW_FUNDS } from "../../../config/api";
import { showErrorModal } from "../../reducers/messageSlice";

import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";

export const withdrawFunds = createAsyncThunk(
  "wallet/withdrawFunds",
  async ({ data, cb, failed }, { dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_WITHDRAW_FUNDS,
        data: data,
      });

      cb();
      toast.success("Withdrawal request created successfully.");

      return response;
    } catch (error) {
      failed();
      const msg = proccessErrorMessage(error);

      dispatch(showErrorModal(msg));
    }
  }
);
