import React from "react";

const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16675 1.16669C3.12918 1.16669 0.666748 3.62912 0.666748 6.66669C0.666748 9.70425 3.12918 12.1667 6.16675 12.1667C7.5055 12.1667 8.73253 11.6884 9.68628 10.8933L13.4799 14.6869C13.6751 14.8822 13.9917 14.8822 14.187 14.6869C14.3822 14.4916 14.3822 14.1751 14.187 13.9798L10.3934 10.1862C11.1884 9.23247 11.6667 8.00544 11.6667 6.66669C11.6667 3.62912 9.20431 1.16669 6.16675 1.16669ZM1.66675 6.66669C1.66675 4.18141 3.68147 2.16669 6.16675 2.16669C8.65203 2.16669 10.6667 4.18141 10.6667 6.66669C10.6667 9.15197 8.65203 11.1667 6.16675 11.1667C3.68147 11.1667 1.66675 9.15197 1.66675 6.66669Z"
        fill="#24292E"
      />
    </svg>
  );
};

export default SearchIcon;
