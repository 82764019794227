import { MenuItem, Select } from "@mui/material";
import React from "react";

const SelectField = ({
  label,
  form,
  id,
  options,
  onChange,
  clearFields,
  sx,
  disabled,
}) => {
  const localHanlechange = (event) => {
    if (id) {
      if (clearFields) {
        clearFields?.forEach((_id) => {
          form.setFieldValue(_id, "");
        });
      }
      form.setFieldValue(id, event.target.value);
    }
  };
  return (
    <div className="w-full">
      <Select
        size="small"
        disabled={disabled}
        sx={{
          ...sx,
          borderRadius: "8px",
          "& .MuiSelect-nativeInput": {},
        }}
        fullWidth
        required
        onChange={onChange ? onChange : localHanlechange}
        value={form?.values[id]}
        displayEmpty
        def
        id={id}
        style={{}}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={
          form?.values[id]
            ? (selected) => (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {selected?.icon && (
                    <img src={selected?.icon} alt="" className="w-4 h-4" />
                  )}
                  {selected?.label}
                </div>
              )
            : null
        }
      >
        <MenuItem
          disabled={true}
          style={{
            // fontFamily: "TTI",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "500",
          }}
          value=""
        >
          {label}
        </MenuItem>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option}
            classes={{ selected: "selected-option" }}
          >
            {option.icon && (
              <img src={option.icon} alt="" className="w-4 h-4 mr-2" />
            )}
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {form?.errors[id] && (
        <div className="text-red-500 font-Source-Sans-Pro text-xs mt-[2px] ml-1">
          {form?.errors[id]}
        </div>
      )}
    </div>
  );
};

export default SelectField;
