import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import CustomButton from "../../components/Buttons/Button";
import CustomInput from "../../components/inputs/CustomInput";
import SelectField from "../../components/inputs/SelectField";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import {
  campaignFrequencyOptions,
  currencyOptions,
  investmentSectorOptions,
  investmentSectorOptions2,
  paymentFrequencyOptions,
  paymentType,
  publicCategoryOptions,
  riskOptions,
} from "../../config/data";
import { TextareaAutosize } from "@mui/material";
import HalDatePicker from "../../components/inputs/HalDatePicker";
import { useFormik } from "formik";
import { createInvestment } from "../../store/actions/investment/createInvestment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createInvestmentSchema } from "../../Helper/validationSchemas";
import { getCategories } from "../../store/actions/investment/getCategories";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import ".//Investment.css";
import BackArrow2 from "../../components/BackArrow2";

const CreateBusiness = () => {
  const [banner, setBanner] = useState(null);

  const [sendMail, setSendMail] = useState(false);
  const [investmentMemo, setInvestmentMemo] = useState(null);

  const [memoFiles, setMemoFiles] = useState([]);

  const [description, setDescription] = useState("");
  const [busLogoPreview, setBusLogoPreview] = useState(null);
  const fileInputRef = useRef(null);
  const memoFileInputRef = useRef(null);
  const loading = useSelector((state) => state?.investment?.pending) || false;
  const categories =
    useSelector((state) => state?.investment?.categories) || [];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleFileChange = (event) => {
    setBanner(event.target.files[0]);
    handleImageChange(event);
  };

  const handleRemoveImage = () => {
    setBusLogoPreview(null);
    setBanner(null);
  };

  const handleMemoFileChange = (event, startupInvesting) => {
    setInvestmentMemo(event.target.files[0]);
    handleMemoChange(event, startupInvesting);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    handleImageChange(e);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleMemoDrop = (e, startupInvesting) => {
    e.preventDefault();
    handleMemoChange(e, startupInvesting);
  };

  const handleMemoDragOver = (e) => {
    e.preventDefault();
  };

  const containerStyle = {
    width: "100%",
    marginTop: "10px",
    position: "relative",
  };

  const handleImageChange = async (e) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;
    const convertedFiles = files[0];

    const fileReader = new FileReader();
    fileReader.readAsDataURL(convertedFiles);
    fileReader.addEventListener("load", (result) => {
      setBusLogoPreview(result.target.result);
    });
    setBanner(convertedFiles);
  };

  const handleMemoChange = async (e, startupInvesting) => {
    if (!e?.dataTransfer?.files[0] && !e?.target?.files[0]) {
      return;
    }
    const files = e?.dataTransfer?.files || e?.target?.files;

    if (startupInvesting) {
      if (memoFiles?.length < 4) {
        setMemoFiles((prevFiles) => [...prevFiles, files[0]]);
      }
    } else {
      setInvestmentMemo(files[0]);
    }
  };

  const handleRemoveMemo = (index, startupInvesting) => {
    if (startupInvesting) {
      const updatedFiles = [...memoFiles];
      updatedFiles.splice(index, 1);
      setMemoFiles(updatedFiles);
    } else {
      setInvestmentMemo(null);
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleMemoDivClick = () => {
    memoFileInputRef.current.click();
  };

  const handleAgreementChange = (event) => {
    setSendMail(event.target.checked);
  };

  const handleSubmit = () => {
    const MAX_FILE_SIZE = 5120; // 5MB
    const bannerSizeKiloBytes = banner?.size / 1024;
    const documentSizeKiloBytes = investmentMemo?.size / 1024;

    if (bannerSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Banner size is greater than the maximum limit");
      return;
    }
    if (documentSizeKiloBytes > MAX_FILE_SIZE) {
      toast.error("Document size is greater than the maximum limit");
      return;
    }

    const formData = new FormData();

    if (form.values?.name) {
      formData.append("name", form.values.name);
    }

    if (form.values?.investment_category_id?.value) {
      formData.append(
        "investment_category_id",
        form.values.investment_category_id.value
      );
    }

    if (form.values?.payout_type?.value !== undefined) {
      formData.append("payout_type", form.values?.payout_type?.value);
    }

    formData.append(
      "campaign_start_date",
      new Date(form.values?.campaign_start_date).toISOString().substring(0, 10)
    );

    if (form.values?.campaign_duration !== undefined) {
      formData.append("campaign_duration", form.values?.campaign_duration);
    }

    if (form.values?.campaign_duration_type?.value) {
      formData.append(
        "campaign_duration_type",
        form.values?.campaign_duration_type?.value
      );
    }

    if (form.values?.minimum_amount !== undefined) {
      formData.append("minimum_amount", form.values?.minimum_amount);
    }

    if (form.values?.maximum_amount !== undefined) {
      formData.append("maximum_amount", form.values?.maximum_amount);
    }

    if (form.values?.dollar !== undefined) {
      formData.append("dollar", form.values?.dollar);
    }

    if (form.values?.risk_factor?.value !== undefined) {
      formData.append("risk_factor", form.values?.risk_factor?.value);
    }
    // if (form.values?.member_admin_perc) {
    formData.append("member_admin_perc", form.values?.member_admin_perc);
    // }
    // if (form.values?.non_member_admin_perc) {
    formData.append(
      "non_member_admin_perc",
      form.values?.non_member_admin_perc
    );
    // }

    if (form.values?.currency?.value !== undefined) {
      formData.append("currency", form.values?.currency?.value);
    }

    if (form.values?.document_links !== undefined) {
      formData.append("document_links", form.values?.document_links);
    }

    if (description) {
      formData.append("description", description);
    }

    if (form.values?.sector?.label !== undefined) {
      formData.append("sector", form.values?.sector?.label);
    }

    if (form.values?.target?.value !== undefined) {
      formData.append("target", form.values?.target?.value);
    }

    // Add files if they exist
    if (banner) {
      formData.append("banner", banner);
    }

    if (
      form.values?.investment_category_id?.name === "Real Estate" ||
      form?.values?.investment_category_id?.name === "Startup Investing" ||
      form?.values?.investment_category_id?.name ===
        "Exchange Traded Commodities"
    ) {
      if (form.values?.liquidity?.value) {
        formData.append("liquidity", form.values?.liquidity?.value);
      }
      if (form.values?.propertyType) {
        formData.append("property_type", form.values?.propertyType?.value);
      }

      if (form.values?.annual_dividend?.value) {
        formData.append("annual_dividend", form.values?.annual_dividend?.value);
      }

      if (form.values?.annualized_target_return) {
        formData.append(
          "annualized_target_return",
          form.values?.annualized_target_return
        );
      }
      if (form.values?.annual_rental_return) {
        formData.append(
          "annual_rental_return",
          form.values?.annual_rental_return
        );
      }

      if (form.values?.projected_timeline?.value) {
        formData.append(
          "projected_timeline",
          form.values?.projected_timeline?.value
        );
      } else if (form.values?.projected_timeline_days) {
        formData.append(
          "projected_timeline",
          `${form.values?.projected_timeline_days} days`
        );
      } else if (form.values?.projected_timeline_years) {
        formData.append(
          "projected_timeline",
          `${form.values?.projected_timeline_years} years`
        );
      }

      memoFiles.forEach((document) => {
        formData.append("documents[]", document);
      });
    } else {
      if (form.values?.roi) {
        formData.append("roi", form.values?.roi);
      }

      if (form.values?.payment_frequency?.value) {
        formData.append(
          "payment_frequency",
          form.values?.payment_frequency?.value
        );
      }

      if (form.values?.duration) {
        formData.append("duration", form.values?.duration);
      }

      if (form.values?.duration_type?.value) {
        formData.append("duration_type", form.values?.duration_type?.value);
      }

      if (investmentMemo) {
        formData.append("document", investmentMemo);
        formData.append("investment_memo", investmentMemo);
      }
    }

    if (sendMail) {
      formData.append("send_email", 1);
    }

    if (form?.values?.propertyLocation) {
      formData.append("property_location", form.values?.propertyLocation);
    }

    if (form.values?.video_title) {
      formData.append("video_title", form.values?.video_title);
    }
    if (form.values?.video_link) {
      formData.append("video_link", form.values?.video_link);
    }
    dispatch(createInvestment({ data: formData, navigate }));
  };

  const clearIconStyle = {
    position: "absolute",
    top: "0px",
    right: "10px",
    background: "#159AA8",
    borderRadius: "50%",
    padding: "4px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)",
    cursor: "pointer",
  };

  const form = useFormik({
    initialValues: {
      name: "",
      banner: "",
      banner_link: "",
      investment_category_id: "0",
      annualized_target_return: "",
      liquidity: "",
      projected_timeline: "",
      projected_timeline_days: "",
      projected_timeline_years: "",
      payout_type: "",
      annual_dividend: "",
      // campaign_start_date: "",
      end_date: "",
      payment_frequency: "",
      roi: "",
      minimum_amount: 0,
      maximum_amount: 0,
      member_admin_perc: 0,
      investment_memo: "",
      non_member_admin_perc: 0,
      dollar: 0,
      currency: "NGR",
      campaign_duration: 6,
      risk_factor: "",
      campaign_duration_type: "days",
      duration: "",
      duration_type: "months",
      documents: null,
      document_links: "https://api.halvestco.com.png",
      description: "",
      send_email: 0,
      video_title: "",
      video_link: "",
    },
    validationSchema: createInvestmentSchema,
    onSubmit: handleSubmit,
  });

  return (
    <DashboardLayout>
      <div className="px-2 lg:px-8  ">
        <div className="flex items-center mb-8 flex-row md:items-center">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <Link
            to="/investment"
            className="font-semibold text-[#475467] text-base flex"
          >
            Business
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 md:mr-2" />
          <span className="font-semibold text-[#475467] text-base bg-[#F9FAFB] p-1 rounded-md md:w-[100px]">
            Create
          </span>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-8 mb-4 md:mb-6">
          <div className=" flex w-full">
            <h1 className="font-semibold text-xl md:text-2xl">
              Create a New Business
            </h1>
          </div>
        </div>
        <BackArrow2 mb={20} ml={0} />

        <div className="p-2">
          {/* <div className="mb-4">
            <h1 className="font-[600] text-base">Investment Detail</h1>
          </div> */}
          <div className="grid grid-cols-2 gap-5 sm:gap-10">
            <div style={containerStyle}>
              <p>Business Logo:</p>
              {busLogoPreview ? (
                <div
                  className="w-[90%] lg:w-[90%] !h-[165px] "
                  style={{
                    height: "10px",
                    border: "1px solid #EAECF0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "10px",
                    textAlign: "center",
                    lineHeight: 1.8,
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <IconButton
                    onClick={handleRemoveImage}
                    style={clearIconStyle}
                  >
                    <ClearIcon style={{ color: "white" }} />
                  </IconButton>
                  <img
                    src={busLogoPreview}
                    alt="Preview"
                    className="object-cover"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              ) : (
                <div
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onClick={handleDivClick}
                  className="mt-3 h-auto sm:!h-[165px] border-[1px] flex flex-col items-center rounded-[10px] text-center leading-[1.8] cursor-pointer justify-center p-[20px] border-[#EAECF0] w-[100%] max-w-[400px]  "
                >
                  <p className="text-sm mt-4">
                    <div className="flex items-center justify-center  ">
                      <img
                        src="/Assets/upload.png"
                        alt="Personal icon"
                        className="w-12 h-12 "
                      />
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        hidden
                        ref={fileInputRef}
                        className="  "
                      />
                    </div>
                    <span className="text-[#159AA8] mt-2">
                      Click to upload{" "}
                    </span>
                    or drag and drop
                  </p>
                  <p className="text-sm">
                    SVG, PNG, JPG, or GIF (max. 800x400px)
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          {/* <div className="p-2"> */}
          <div className="grid sm:grid-cols-3 gap-5 mt-12 investment-col w-full">
            <div className="mb-4 lg:mb:0  w-full ">
              <p className="">Investment Name:</p>
              <CustomInput
                id="name"
                width="100%"
                form={form}
                placeholder={"Enter Investment Name"}
                height="16px"
                mt="10"
                mr="10px"
                mb="10px"
                borderRadius={"10px"}
                className="mobile-input-width"
              />
            </div>

            <div className=" mb-4 lg:mb:0  w-full">
              <p className="mb-0">Investment Category</p>
              <SelectField
                placeholder="Select Category"
                id="investment_category_id"
                form={form}
                options={categories?.map((item) => ({
                  ...item,
                  label: item.name,
                  value: item.id,
                }))}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>

            {form.values?.investment_category_id?.name !== "Real Estate" &&
              form.values?.investment_category_id?.name !==
                "Startup Investing" &&
              form?.values?.investment_category_id?.name !==
                "Exchange Traded Commodities" && (
                <div className="mb-4 lg:mb:0 lg:mr-8 w-full">
                  <p className="">Expected Duration</p>
                  <div className="flex flex-row">
                    <CustomInput
                      id="duration"
                      width="70px"
                      form={form}
                      type="number"
                      placeholder={"Enter Duration"}
                      height="12px"
                      padding={"15px"}
                      mr="10px"
                      mb="10px"
                      borderRadius={"5px"}
                      className="mobile-input-width"
                    />
                    <SelectField
                      placeholder="Select"
                      id="duration_type"
                      form={form}
                      options={[{ label: "Months", value: "Months" }]}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "52px",
                        borderRadius: "10px",
                        marginTop: "0px",
                      }}
                      className="mobile-select"
                    />
                  </div>
                </div>
              )}
            {form.values?.investment_category_id?.name !== "Real Estate" &&
              form.values?.investment_category_id?.name !==
                "Startup Investing" &&
              form?.values?.investment_category_id?.name !==
                "Exchange Traded Commodities" && (
                <div className="mb-4 lg:mb:0   w-full">
                  <p className="">Expected ROI</p>
                  <CustomInput
                    id="roi"
                    width="100%"
                    form={form}
                    type="number"
                    placeholder={"Enter Expected ROI"}
                    height="16px"
                    mt="10"
                    mr="10px"
                    borderRadius={"10px"}
                    className="mobile-input-width"
                  />
                </div>
              )}
            {/* </div> */}

            {/* <div className=" grid sm:grid-cols-3 gap-5  w-full"> */}
            <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full  ">
              <p className="">Funding Goal:</p>
              <CustomInput
                id="maximum_amount"
                width="100%"
                type="currency"
                form={form}
                placeholder={"Enter Amount"}
                height="12px"
                mt="10"
                mb="2px"
                mr="10px"
                borderRadius={"10px"}
                className="mobile-input-width"
              />
            </div>
            <div className="mb-4 lg:mb:0 lg:pl-0 lg:mr-8 w-full">
              <p className="">Minimum Amount</p>
              <CustomInput
                id="minimum_amount"
                width="100%"
                form={form}
                type="currency"
                placeholder={"Enter Amount"}
                height="12px"
                mt="10"
                mb="2px"
                mr="10px"
                borderRadius={"10px"}
                className="mobile-input-width"
              />
            </div>

            <div className="  mb-4 lg:mb:0 w-full  ">
              <span>Currency</span>
              <SelectField
                placeholder="Select Currency"
                id="currency"
                form={form}
                options={currencyOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  marginTop: "0px",
                  borderRadius: "10px",
                }}
                className="mobile-select"
              />
            </div>
            {/* </div> */}

            {/* <div className="grid sm:grid-cols-3 gap-5 mt-4  "> */}

            {(form.values?.investment_category_id?.name === "Real Estate" ||
              form.values?.investment_category_id?.name ===
                "Startup Investing" ||
              form?.values?.investment_category_id?.name ===
                "Exchange Traded Commodities") && (
              <>
                <div className=" ">
                  {form?.values?.investment_category_id?.name ===
                  "Real Estate" ? (
                    <>
                      <p className="w-[max-content]">
                        Expected Annual Rental Return
                      </p>
                      <CustomInput
                        id="annual_rental_return"
                        width="100%"
                        form={form}
                        type="number"
                        placeholder={"Enter Percentage"}
                        height="12px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        // className="mobile-input-width"
                      />
                    </>
                  ) : form?.values?.investment_category_id?.name ===
                    "Exchange Traded Commodities" ? (
                    <>
                      <p className="w-[max-content]">Minimum Expected Return</p>
                      <CustomInput
                        id="annualized_target_return"
                        width="100%"
                        form={form}
                        type="number"
                        placeholder={"Enter Percentage"}
                        height="12px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        // className="mobile-input-width"
                      />
                    </>
                  ) : (
                    <>
                      <p className="w-[max-content]">
                        Annualized Target Return
                      </p>
                      <CustomInput
                        id="annualized_target_return"
                        width="100%"
                        form={form}
                        type="number"
                        placeholder={"Enter Percentage"}
                        height="12px"
                        mt="10"
                        mr="10px"
                        borderRadius={"10px"}
                        // className="mobile-input-width"
                      />
                    </>
                  )}
                </div>

                {form.values?.investment_category_id?.name === "Real Estate" ? (
                  <div className=" ">
                    <p className="w-[max-content]">Property Type</p>
                    <SelectField
                      placeholder="Select Category"
                      id="propertyType"
                      form={form}
                      options={[
                        { value: "Residential", label: "Residential" },
                        { value: "Commercial", label: "Commercial" },
                      ]}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "1px",
                      }}
                      className="mobile-select"
                    />
                  </div>
                ) : (
                  <div className=" ">
                    <p className="w-[max-content]">Liquidity</p>
                    <SelectField
                      placeholder="Select Category"
                      id="liquidity"
                      form={form}
                      options={
                        form.values?.investment_category_id?.name ===
                          "Real Estate" ||
                        form?.values?.investment_category_id?.name ===
                          "Exchange Traded Commodities"
                          ? [
                              { value: "Yes", label: "Yes" },
                              { value: "No", label: "No" },
                            ]
                          : [
                              { value: "Annual", label: "Annual" },
                              { value: "None", label: "None" },
                            ]
                      }
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "1px",
                      }}
                      className="mobile-select"
                    />
                  </div>
                )}

                <div className=" ">
                  <p className="w-[max-content]">
                    Projected timeline
                    {form.values?.investment_category_id?.name === "Real Estate"
                      ? "(years)"
                      : form?.values?.investment_category_id?.name ===
                        "Exchange Traded Commodities"
                      ? "(days)"
                      : ""}
                  </p>

                  {form.values?.investment_category_id?.name ===
                  "Real Estate" ? (
                    <CustomInput
                      id="projected_timeline_years"
                      width="100%"
                      type="number"
                      form={form}
                      placeholder={"Enter Duration"}
                      height="16px"
                      mt="10"
                      mr="10px"
                      borderRadius={"10px"}
                      // className="mobile-input-width"
                    />
                  ) : form?.values?.investment_category_id?.name ===
                    "Exchange Traded Commodities" ? (
                    <CustomInput
                      id="projected_timeline_days"
                      width="100%"
                      type="number"
                      form={form}
                      placeholder={"Enter Duration"}
                      height="16px"
                      mt="10"
                      mr="10px"
                      borderRadius={"10px"}
                      // className="mobile-input-width"
                    />
                  ) : (
                    <SelectField
                      placeholder="Select projected timeline"
                      id="projected_timeline"
                      form={form}
                      options={[
                        { value: "5 - 7 years", label: "5 - 7 years" },
                        { value: "7 - 10 years", label: "7 - 10 years" },
                      ]}
                      sx={{
                        width: "100%",
                        padding: "1px",
                        height: "44px",
                        borderRadius: "10px",
                        marginTop: "1px",
                      }}
                      className="mobile-select"
                    />
                  )}
                </div>

                <div className=" ">
                  {form.values?.investment_category_id?.name ===
                    "Real Estate" ||
                  form?.values?.investment_category_id?.name ===
                    "Exchange Traded Commodities" ? (
                    <p className="w-[max-content]">Exit Windows</p>
                  ) : (
                    <p className="w-[max-content]">Annual Dividend</p>
                  )}
                  <SelectField
                    placeholder="Select Category"
                    id="annual_dividend"
                    form={form}
                    options={[
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ]}
                    sx={{
                      width: "100%",
                      padding: "1px",
                      height: "44px",
                      borderRadius: "10px",
                      marginTop: "1px",
                    }}
                    className="mobile-select"
                  />
                </div>
              </>
            )}

            {form.values?.investment_category_id?.name === "Real Estate" ? (
              <div className="mb-4 lg:mb:0  w-full lg:mr-8">
                <p className="">Property location</p>
                <CustomInput
                  id="propertyLocation"
                  width="100%"
                  form={form}
                  // type="text"
                  placeholder={"Enter Property location"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  borderRadius={"10px"}
                  // className="mobile-input-width"
                />
              </div>
            ) : (
              <div className="mb-4 lg:mb:0  w-full lg:mr-8">
                <p className="">Investment Sector</p>
                <SelectField
                  placeholder="Select Sector"
                  id="sector"
                  form={form}
                  options={
                    form.values?.investment_category_id?.name ===
                      "Real Estate" ||
                    form?.values?.investment_category_id?.name ===
                      "Exchange Traded Commodities"
                      ? investmentSectorOptions2
                      : investmentSectorOptions
                  }
                  sx={{
                    width: "100%",
                    padding: "1px",
                    height: "52px",
                    borderRadius: "10px",
                    marginTop: "1px",
                  }}
                  className="mobile-select"
                />
              </div>
            )}

            {form.values?.investment_category_id?.name !== "Real Estate" &&
              form.values?.investment_category_id?.name !==
                "Startup Investing" &&
              form?.values?.investment_category_id?.name !==
                "Exchange Traded Commodities" && (
                <div className=" mb-4 lg:mb:0 w-full">
                  <p className="">Payment Frequency</p>
                  <SelectField
                    placeholder="Select Frequency"
                    id="payment_frequency"
                    form={form}
                    options={paymentFrequencyOptions}
                    sx={{
                      width: "100%",
                      padding: "1px",
                      height: "52px",
                      borderRadius: "10px",
                      marginTop: "0px",
                    }}
                    className="mobile-select"
                  />
                </div>
              )}
            {/* </div> */}

            {/* <div className=" mb-4 lg:mb:0 w-full grid sm:grid-cols-3 gap-5 mt-12 lg:items-center lg:gap-5"> */}
            <div className="mb-4 lg:mb:0   ">
              <label>Campaign Start Date</label>
              {/* <div
                style={{
                  border: "1px solid #EAECF0",
                  width: "100%", // Set width to 100%
                  height: "52px",
                  marginTop: "4px",
                  marginRight: "20px",
                  borderRadius: "10px",
                }}
              > */}
              <HalDatePicker
                label="Start Date"
                id={"campaign_start_date"}
                form={form}
                small
                rightImage
                className="-mr-5"
              />
              {/* </div> */}
            </div>

            <div className="mb-4 lg:mb:0  ">
              <p className="">Campaign Duration</p>
              <CustomInput
                id="campaign_duration"
                width="100%"
                type="number"
                form={form}
                placeholder={"Enter Duration"}
                height="16px"
                mt="10"
                mr="10px"
                borderRadius={"10px"}
                // className="mobile-input-width"
              />
            </div>
            <div className="mb-4 lg:mb:0  ">
              <p className="">Campaign Duration Type</p>
              <SelectField
                placeholder="Select Frequency"
                id="campaign_duration_type"
                form={form}
                options={campaignFrequencyOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "0px",
                }}
                className="mobile-select"
              />
            </div>

            <div className=" ">
              <p className="w-[max-content]">Payout Type</p>
              <SelectField
                placeholder="Select Payout Type"
                id="payout_type"
                form={form}
                options={paymentType}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "1px",
                }}
                className="mobile-select"
              />
            </div>

            <div className="  ">
              <p className="">Risk Rating</p>
              <SelectField
                placeholder="Select Rating"
                id="risk_factor"
                form={form}
                options={riskOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                  marginTop: "0px",
                }}
                className="mobile-select"
              />
            </div>

            <div className="">
              <p className="">Publish Investment To:</p>
              <SelectField
                id="target"
                form={form}
                options={publicCategoryOptions}
                sx={{
                  width: "100%",
                  padding: "1px",
                  height: "52px",
                  borderRadius: "10px",
                }}
                className="mobile-select"
              />
            </div>

            {(form.values?.target?.value === "members" ||
              form.values?.target?.value === "everyone") && (
              <div className=" ">
                <p className="w-[max-content]">Admin fee for Members</p>
                <CustomInput
                  id="member_admin_perc"
                  width="100%"
                  form={form}
                  type="number"
                  placeholder={"Enter Percentage"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  borderRadius={"10px"}
                  // className="mobile-input-width"
                />
              </div>
            )}
            {(form.values?.target?.value === "non_members" ||
              form.values?.target?.value === "everyone") && (
              <div className="">
                <p className="">Admin fee for Non-members</p>
                <CustomInput
                  id="non_member_admin_perc"
                  width="100%"
                  form={form}
                  type="number"
                  placeholder={"Enter Percentage"}
                  height="12px"
                  mt="10"
                  mr="10px"
                  borderRadius={"10px"}
                  // className="mobile-input-width"
                />
              </div>
            )}

            <div className="mb-4 lg:mb:0 w-full">
              <p>Video Title</p>
              <CustomInput
                id="video_title"
                width="100%"
                form={form}
                placeholder={"Enter Video Title"}
                height="12px"
                mt="10"
                mr="10px"
                mb="10px"
                borderRadius={"10px"}
                className="mobile-input-width"
              />
            </div>

            <div className="mb-4 lg:mb:0 w-full">
              <p>Video Link</p>
              <CustomInput
                id="video_link"
                width="100%"
                form={form}
                placeholder={"Enter Video Link"}
                height="12px"
                mt="10"
                mr="10px"
                mb="10px"
                borderRadius={"10px"}
                className="mobile-input-width"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-5 sm:gap-10">
          {memoFiles?.length > 0 ? (
            <div style={containerStyle}>
              {memoFiles?.map((file, index) => (
                <div key={index} className="mb-2">
                  <IconButton
                    onClick={() => handleRemoveMemo(index, true)}
                    style={clearIconStyle}
                  >
                    <ClearIcon />
                  </IconButton>
                  <span>{file.name}</span>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          <div style={containerStyle}>
            <p>Campaign Documents:</p>
            {form.values?.investment_category_id?.name === "Real Estate" ||
            form?.values?.investment_category_id?.name ===
              "Startup Investing" ||
            form?.values?.investment_category_id?.name ===
              "Exchange Traded Commodities" ? (
              <div>
                {memoFiles?.length < 4 && (
                  <div
                    onDrop={(e) => handleMemoDrop(e, true)}
                    onDragOver={handleMemoDragOver}
                    onClick={handleMemoDivClick}
                    className="mt-3 h-auto sm:!h-[165px] border-[1px] flex flex-col items-center rounded-[10px] text-center leading-[1.8] cursor-pointer justify-center p-[20px] border-[#EAECF0] w-[100%] max-w-[400px]  "
                  >
                    <div>
                      <div className="flex items-center justify-center">
                        <img
                          src="/Assets/upload.png"
                          alt="Personal icon"
                          className="w-12 h-12 mr-4"
                        />
                        <input
                          type="file"
                          accept=".pdf, .doc, .docx"
                          onChange={(e) => handleMemoFileChange(e, true)}
                          hidden
                          className=" w-[250px] h-[60px] "
                          ref={memoFileInputRef}
                        />
                      </div>
                      {memoFiles?.length === 0 ? (
                        <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                          Click to upload{" "}
                        </span>
                      ) : (
                        <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                          Add up to four (4) Memo{" "}
                        </span>
                      )}
                      <span className="text-[12px] sm:text-sm mt-4">
                        or drag and drop
                      </span>
                    </div>
                    <p className="text-[12px] sm:text-sm">
                      DOC or PDF (max. 20MB)
                    </p>
                  </div>
                )}
              </div>
            ) : investmentMemo ? (
              <div
                className="w-[90%] lg:w-[350px] mt-3 h-auto sm:!h-[165px]"
                style={{
                  height: "130px",
                  border: "1px solid #EAECF0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "10px",
                  textAlign: "center",
                  lineHeight: 1.8,
                  marginTop: "10px",
                  cursor: "pointer",
                }}
              >
                <IconButton onClick={handleRemoveMemo} style={clearIconStyle}>
                  <ClearIcon />
                </IconButton>
                <span>{investmentMemo.name}</span>
              </div>
            ) : (
              <div
                onDrop={handleMemoDrop}
                onDragOver={handleMemoDragOver}
                onClick={handleMemoDivClick}
                className="mt-3 h-auto sm:!h-[165px] border-[1px] flex flex-col items-center rounded-[10px] text-center leading-[1.8] cursor-pointer justify-center p-[20px] border-[#EAECF0] w-[100%] max-w-[400px]  "
              >
                <p className="text-[12px] sm:text-sm mt-4">
                  <div className="flex items-center justify-center">
                    <img
                      src="/Assets/upload.png"
                      alt="Personal icon"
                      className="w-12 h-12 mr-4"
                    />
                    <input
                      type="file"
                      accept=".pdf, .doc, .docx"
                      onChange={handleMemoFileChange}
                      hidden
                      ref={memoFileInputRef}
                    />
                  </div>
                  <span className="text-[12px] sm:text-sm text-[#159AA8] mt-2">
                    Click to upload{" "}
                  </span>
                  or drag and drop
                </p>
                <p className="text-[12px] sm:text-sm">DOC or PDF (max. 20MB)</p>
              </div>
            )}
          </div>
        </div>
        <div className="mt-8 p-2">
          <h1>Campaign Description</h1>
          <div className="border-solid border border-gray-300 rounded-md overflow-hidden w-full">
            <ReactQuill
              value={description}
              onChange={(value) => setDescription(value)}
              placeholder="Type your title here..."
            />
          </div>
        </div>
        <div className="mt-5 p-2" id="terms">
          <label style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={sendMail}
              onChange={handleAgreementChange}
              style={{ width: "24px", height: "24px", marginRight: "10px" }}
            />
            <span>Send email notification to target audience</span>
          </label>
        </div>

        <div className="flex justify-center lg:justify-end items-center">
          <CustomButton
            variant="outlined"
            customColor="#fff"
            children="Cancel"
            width="100px"
            height="45px"
            padding="10px"
            borderColor="#D0D5DD"
            margin="5px 5px"
            color="#000"
            // onClick={() => navigate(-1)}
          />
          <CustomButton
            variant="contained"
            customColor="#159AA8"
            children="Create a New Investmet"
            width="206px"
            height="45px"
            loading={loading}
            buttonStyles={{ width: "300px" }}
            className={"!w-[300px]"}
            padding="10px"
            margin="15px 0"
            color="#fff"
            disabled={!form.isValid || !form.dirty || !description}
            onClick={form.handleSubmit}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CreateBusiness;
