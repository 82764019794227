import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_ADD_BANK_DETAILS,
  API_GET_BANK_NAMES,
  API_GET_USER_BANK,
  API_REMOVE_BANK,
} from "../../../config/api";

export const addBankDetails = createAsyncThunk(
  "banks/add",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_ADD_BANK_DETAILS,
        data: payload.data,
      });

      dispatch(getBankList());
      return response;
    } catch (error) {
      toast.error(error?.response?.data?.errors?.account_number);
      payload.setSubmitting(false);
    }
  }
);

export const getBankList = createAsyncThunk(
  "users/getBankList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_BANK_NAMES,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
export const getUserBank = createAsyncThunk(
  "banks/getUserBankList",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_USER_BANK}/${userId}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
export const removeUserBank = createAsyncThunk(
  "banks/removeBankList",
  async (bankId, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "DELETE",
        path: `${API_REMOVE_BANK}/${bankId}`,
      });

      toast.success(response?.message);
      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      throw error;
    }
  }
);
