import { Autocomplete, Modal, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { investSchema } from "../../Helper/validationSchemas";
import SelectField from "../inputs/SelectField";
import { _countries } from "../../config/data";
import {
  getProductInvestmentByCurrency,
  investmentForUser,
} from "../../store/actions/investment/investment";
import { toast } from "react-toastify";

const InitiateInvest = ({ open, onClose, userId }) => {
  const dispatch = useDispatch();

  const [currency, setCurrency] = useState();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [_investmentProductCurrency, setInvestmentProductCurrency] = useState(
    []
  );

  const loading = useSelector(
    (state) => state?.investment?.investmentProductCurrencyPending
  );

  const investmentProductCurrency = useSelector(
    (state) => state?.investment?.investmentProductCurrency || []
  );

  console.log("DE", _investmentProductCurrency);

  const form = useFormik({
    initialValues: {
      investment_id: "",
      amount: "",
      fees: "",
    },
    validationSchema: investSchema,
  });

  const handleSubmit = () => {
    setSubmitLoading(true);
    const payload = {
      id: userId,
      data: {
        investment_product_id: form?.values.investment_id?.value,
        amount: form.values.amount,
        admin_charges: form.values.fees,
        debit_amount: Number(form.values.amount) + Number(form.values.fees),
        status: 0,
        payment_type: "transfer",
        transaction_id: "12844004322df",
      },
      failed: () => {
        setSubmitLoading(false);
      },
      cb: () => {
        form.resetForm();
        onClose();
        setSubmitLoading(false);
        toast.success("User Investment Added Successfully");
      },
    };

    dispatch(investmentForUser(payload));
  };

  useEffect(() => {
    if (investmentProductCurrency?.data)
      setInvestmentProductCurrency(investmentProductCurrency?.data);
  }, [investmentProductCurrency?.data]);

  useEffect(() => {
    if (currency) dispatch(getProductInvestmentByCurrency({ currency }));
  }, [currency]);

  useEffect(() => {
    setCurrency(form.values.currency);
  }, [form]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5" style={{ padding: "10px 20px" }}>
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div className="w-full  my-2">
            <label className="text-sm font-[600] text-[#344054]">
              Select Currency
            </label>
            <div className="w-full mb-4">
              <SelectField
                label="Choose Currency"
                id="currency"
                form={form}
                options={[
                  { value: "NGR", label: "Naira (₦)" },
                  { value: "USD", label: "USD ($)" },
                ]}
                sx={{ width: "100%", height: "50px" }}
              />
            </div>
            <label className="text-sm font-[600] text-[#344054]">
              Select investment
            </label>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  width="34px"
                  height="34px"
                  alt="Button Icon"
                  src={"/Assets/loading2.gif"}
                />
              </div>
            ) : (
              <div className="w-full mb-4">
                <SelectField
                  label="Choose Investment"
                  id="investment_id"
                  form={form}
                  options={
                    _investmentProductCurrency &&
                    _investmentProductCurrency?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  }
                  sx={{ width: "100%", height: "50px" }}
                />
              </div>
            )}
            <label className="text-sm font-[600] text-[#344054]">Amount</label>
            <div className="w-full mb-4">
              <CustomInput
                // width="400px"
                fullWidth
                height="18px"
                id="amount"
                form={form}
                placeholder={"Enter amount"}
                borderColor="#D0D5DD"
                type="currency"
                mt="10px"
                pt="28px"
              />
            </div>
            <label className="text-sm font-[600] text-[#344054]">
              Admin fee
            </label>
            <div className="w-full mb-4">
              <CustomInput
                // width="400px"
                fullWidth
                height="18px"
                id="fees"
                type="currency"
                form={form}
                placeholder={"Enter admin fee"}
                borderColor="#D0D5DD"
                mt="10px"
                pt="28px"
              />
            </div>
            <div className="flex w-full  items-center">
              <CustomButton
                variant="contained"
                customColor="#159AA8"
                children="Continue"
                width="100%"
                height="45px"
                disabled={
                  form?.values?.currency?.value === "USD"
                    ? false
                    : !form?.dirty ||
                      !form?.values.investment_id?.value ||
                      !form?.isValid
                }
                loading={submitLoading}
                padding="10px"
                margin="15px 0"
                color="#fff"
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InitiateInvest;
