import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { proccessErrorMessage } from "../../../Helper/proccessErrorMessage";
import {
  API_CREATE_CATEGORY_INVESTMENT,
  API_CREATE_PRODUCT_INVESTMENT,
  API_GET_ALL_CATEGORY_INVESTMENT,
  API_GET_CATEGORY_BY_DETAIL,
  API_GET_INVESTMENT_BY_INVESTOR,
  API_GET_PRODUCT_BY_DETAIL,
  API_GET_PRODUCT_INVESTMENT_BY_CURRENCY,
  API_UPDATE_CATEGORY_INVESTMENT,
  API_UPDATE_PRODUCT_INVESTMENT,
  API_UPDATE_USER_INVESTMENT,
} from "../../../config/api";

export const addCategoryInvestment = createAsyncThunk(
  "investmentCategory/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_CATEGORY_INVESTMENT,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const updateCategoryInvestment = createAsyncThunk(
  "investmentCategory/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: `${API_UPDATE_CATEGORY_INVESTMENT}${data?.id}`,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const getAllCategoryInvestment = createAsyncThunk(
  "investmentCategory/getAll",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_ALL_CATEGORY_INVESTMENT,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const getCategoryByDetail = createAsyncThunk(
  "investmentCategory/getCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_CATEGORY_BY_DETAIL}${data?.id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const addProductInvestment = createAsyncThunk(
  "investmentProduct/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "POST",
        path: API_CREATE_PRODUCT_INVESTMENT,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
export const updateProductInvestment = createAsyncThunk(
  "investmentProduct/update",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: `${API_UPDATE_PRODUCT_INVESTMENT}${data?.id}`,
        data,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const getProductByDetail = createAsyncThunk(
  "investmentProduct/getCategory",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: `${API_GET_PRODUCT_BY_DETAIL}${data?.id}`,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const getProductInvestmentByCurrency = createAsyncThunk(
  "investmentProductCurrency/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_PRODUCT_INVESTMENT_BY_CURRENCY + data.currency.value,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const investmentForUser = createAsyncThunk(
  "investmentProduct/investForUser",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: API_UPDATE_USER_INVESTMENT + payload.id,
        data: payload.data,
      });
      payload.cb();
      return response?.data;
    } catch (error) {
      payload.failed();
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);

export const getInvestmentByInvestor = createAsyncThunk(
  "investmentByInvestor/get",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Client({
        method: "GET",
        path: API_GET_INVESTMENT_BY_INVESTOR,
      });

      return response?.data;
    } catch (error) {
      const msg = proccessErrorMessage(error);
      toast.error(msg);
      return rejectWithValue(error.message);
    }
  }
);
