import { Autocomplete, Modal, TextField } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import CustomInput from "../inputs/CustomInput";
import CustomButton from "../Buttons/Button";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { bankSchema, fundWalletSchema } from "../../Helper/validationSchemas";
import { fundWalletOTP } from "../../store/actions/userManagement/fundWalletOTP";
import { fundWalletConfirmation } from "../../store/actions/userManagement/fundWalletConfirmation";
import SelectField from "../inputs/SelectField";
import {
  _countries,
  currencyOptions,
  formatArray,
  publicCategoryOptions,
} from "../../config/data";
import { getAccountName } from "../../store/actions/userManagement/getAccountName";
import { getBankList } from "../../store/actions/userManagement/getBankList";
import { withdrawFunds } from "../../store/actions/userManagement/withdrawFunds";

const InitiateWithdrawal = ({
  open,
  onClose,
  userId,
  setShowWithdrawalModal,
}) => {
  const dispatch = useDispatch();

  const [selectedBank, setSelectedBank] = useState("");
  const [a, seta] = useState();

  const { banks } = useSelector((state) => state?.allBanks);
  // const { userBanks } = useSelector((state) => state?.allBanks);
  // const loading = useSelector((state) => state?.allBanks?.isAddBank);

  const form = useFormik({
    initialValues: {
      account_name: "",
      amount: "",
      account_number: "",
      bank_type: {
        value: "International Bank Account",
        label: "International Bank Account",
      },
      beneficiary_country: {
        value: "United States",
        label: "United States",
      },
    },
    validationSchema: bankSchema.pick(["account_name", "account_number"]),
  });

  const handleBankDetails = () => {
    form?.setSubmitting(true);
    const payload = {
      data:
        form?.values?.currency?.value === "USD"
          ? {
              bank_type: form.values.bank_type?.value,

              beneficiary_bank_address: form.values.beneficiary_bank_address,
              beneficiary_address: form.values.beneficiary_address,
              beneficiary_country: form.values.beneficiary_country?.value,
              account_number: form.values.account_numberUSD,

              account_name: form.values.beneficiary_bank_name,
              currency: form.values.currency?.value,
              beneficiary_name: form.values.beneficiary_name,

              amount: form.values.amount,
              user_id: userId,
            }
          : {
              bank_holder: form.values.account_name,
              bank_account: form.values.account_number,
              bank_name: selectedBank?.label,
              currency: form.values.currency?.value,
              bank_code: form.values.bank_code?.code,
              user_id: userId,
              amount: form.values.amount,
            },
      cb: () => {
        form.resetForm();
        onClose();
        form?.setSubmitting(false);
        // cb();
      },
      failed: () => {
        form?.setSubmitting(false);
      },
    };

    if (form.values?.bank_type?.value === "Nigerian Bank Account") {
      payload.data.swift_code = form.values.swift_code || "N/A";
      payload.data.sort_code = form.values.sort_code || "N/A";
    } else if (form.values?.bank_type?.value === "International Bank Account") {
      payload.data.routing_number = form.values.routing_number || "N/A";
      payload.data.SWIFT_BIC = form.values.SWIFT_BIC || "N/A";
      payload.data.IBAN = form.values.IBAN || "N/A";
    }

    dispatch(withdrawFunds(payload));
  };

  useEffect(() => {
    dispatch(getBankList());
  }, []);

  const [gettingAccountName, setgettingAccountName] = useState(false);

  useEffect(() => {
    const _account_number = form?.values?.account_number;
    if (_account_number?.length === 10) {
      setgettingAccountName(true);
      dispatch(
        getAccountName({
          payload: {
            bank_code: selectedBank?.code,
            account_number: _account_number,
          },
          cb: (accountName) => {
            setgettingAccountName(false);
            form.setFieldValue("account_name", accountName);
          },
          deleteLastDegit: () => {
            form.setFieldValue("account_number", _account_number?.slice(0, 9));
          },
          setgettingAccountName,
        })
      );
    } else form.setFieldValue("account_name", "");
  }, [form?.values?.account_number, selectedBank]);

  useEffect(() => {
    seta(form.values.currency);
  }, [form]);
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="custom-modal-title"
      aria-describedby="custom-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          maxHeight: "80%",
          overflowY: "scroll",
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 2,
        }}
      >
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            top: 19,
            right: 10,
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </IconButton>

        <div className="w-full p-5" style={{ padding: "10px 20px" }}>
          <img
            src="/Assets/approveIcon.png"
            alt=""
            className="w-12 h-12 mb-5"
          />
          <div className="w-full  my-2">
            <label className="text-sm font-[600] text-[#344054]">
              Select Currency
            </label>
            <div className="w-full mb-4">
              <SelectField
                label="Choose Currency"
                id="currency"
                form={form}
                options={[
                  { value: "NGR", label: "Naira (₦)" },
                  { value: "USD", label: "USD ($)" },
                ]}
                sx={{ width: "100%", height: "50px" }}
              />
            </div>
            <label className="text-sm font-[600] text-[#344054]">Amount</label>
            <div className="w-full mb-4">
              <CustomInput
                // width="400px"
                fullWidth
                height="18px"
                id="amount"
                type="currency"
                form={form}
                placeholder={"Enter amount"}
                borderColor="#D0D5DD"
                mt="10px"
                pt="28px"
              />
            </div>
            {form.values?.currency?.value === "USD" ? (
              <>
                <label className="text-sm font-[600] text-[#344054]">
                  Bank Type
                </label>
                <div className="w-full relative mb-4">
                  <SelectField
                    label="Choose Bank Type"
                    id="bank_type"
                    clearFields={[
                      // "account_name",
                      "account_numberUSD",
                      "sort_code",
                      "swift_code",
                      "routing_number",
                      "IBAN",
                      "beneficiary_bank_name",
                      "beneficiary_name",
                      "SWIFT_BIC",
                      "beneficiary_bank_address",
                      "beneficiary_address",
                      "beneficiary_country",
                    ]}
                    form={form}
                    options={[
                      {
                        value: "Nigerian Bank Account",
                        label: "Nigerian Bank Account",
                      },
                      {
                        value: "International Bank Account",
                        label: "International Bank Account",
                      },
                    ]}
                    sx={{ width: "100%", height: "50px" }}
                  />
                </div>
                <label className="text-sm font-[600] text-[#344054]">
                  Beneficiary Bank Name
                </label>
                <div className="w-full relative mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="beneficiary_bank_name"
                    form={form}
                    placeholder={"Enter Beneficiary Bank Name "}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div>
                {/* <label className="text-sm font-[600] text-[#344054]">
                  Beneficiary Bank Address
                </label>
                <div className="w-full relative mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="beneficiary_bank_address"
                    form={form}
                    placeholder={"Enter Beneficiary Bank Address "}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div>
                <label className="text-sm font-[600] text-[#344054]">
                  Beneficiary Address
                </label>
                <div className="w-full relative mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="beneficiary_address"
                    form={form}
                    placeholder={"Enter Beneficiary Address "}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div> */}

                {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                  <></>
                ) : (
                  <>
                    <label className="text-sm font-[600] text-[#344054]">
                      Beneficiary Country
                    </label>
                    <div className="w-full mb-4">
                      <SelectField
                        label="Choose Beneficiary Country"
                        id="beneficiary_country"
                        form={form}
                        options={_countries}
                        sx={{ width: "100%", height: "50px" }}
                      />
                    </div>
                  </>
                )}

                <label className="text-sm font-[600] text-[#344054]">
                  Account Number
                </label>
                <div className="w-full mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="account_numberUSD"
                    type={"number"}
                    form={form}
                    placeholder={"Enter your Account Number"}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div>
                <label className="text-sm font-[600] text-[#344054]">
                  Beneficiary Name
                </label>
                <div className="w-full relative mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="beneficiary_name"
                    form={form}
                    placeholder={"Enter Beneficiary Name "}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div>
                {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                  <></>
                ) : (
                  <>
                    {/* <label className="text-sm font-[600] text-[#344054]">
                      Routing Number
                    </label>
                    <div className="w-full mb-4">
                      <CustomInput
                        // width="400px"
                        fullWidth
                        height="18px"
                        id="routing_number"
                        // type={"number"}
                        form={form}
                        placeholder={"Enter your Routing Number"}
                        borderColor="#D0D5DD"
                        mt="10px"
                        pt="28px"
                      />
                    </div> */}
                  </>
                )}
                {form.values?.bank_type?.value === "Nigerian Bank Account" ? (
                  <>
                    {" "}
                    {/* <label className="text-sm font-[600] text-[#344054]">
                      Sort Code
                    </label>
                    <div className="w-full mb-4">
                      <CustomInput
                        // width="400px"
                        fullWidth
                        height="18px"
                        id="sort_code"
                        // type={"number"}
                        form={form}
                        placeholder={"Enter your  Sort Code "}
                        borderColor="#D0D5DD"
                        mt="10px"
                        pt="28px"
                      />
                    </div>
                    <label className="text-sm font-[600] text-[#344054]">
                      Swift Code
                    </label>
                    <div className="w-full mb-4">
                      <CustomInput
                        // width="400px"
                        fullWidth
                        height="18px"
                        id="swift_code"
                        // type={"number"}
                        form={form}
                        placeholder={"Enter your Swift Code"}
                        borderColor="#D0D5DD"
                        mt="10px"
                        pt="28px"
                      />
                    </div> */}
                  </>
                ) : (
                  <>
                    {/* <label className="text-sm font-[600] text-[#344054]">
                      SWIFT / BIC
                    </label>
                    <div className="w-full mb-4">
                      <CustomInput
                        // width="400px"
                        fullWidth
                        height="18px"
                        id="SWIFT_BIC"
                        // type={"number"}
                        form={form}
                        placeholder={"Enter your SWIFT / BIC"}
                        borderColor="#D0D5DD"
                        mt="10px"
                        pt="28px"
                      />
                    </div> */}
                    {/* <label className="text-sm font-[600] text-[#344054]">
                      IBAN
                    </label>
                    <div className="w-full mb-4">
                      <CustomInput
                        // width="400px"
                        fullWidth
                        height="18px"
                        id="IBAN"
                        // type={"number"}
                        form={form}
                        placeholder={"Enter your IBAN"}
                        borderColor="#D0D5DD"
                        mt="10px"
                        pt="28px"
                      />
                    </div> */}
                  </>
                )}
              </>
            ) : (
              <>
                <label className="text-sm font-[600] text-[#344054]">
                  Select Bank
                </label>
                <div className="w-full mb-4">
                  <Stack spacing={2} sx={{ width: "100%" }}>
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={
                        form.values?.currency?.value === "USD"
                          ? []
                          : formatArray(banks, "name")
                      }
                      value={selectedBank}
                      onChange={(_, newValue) => {
                        form?.setFieldValue("bank_code", newValue);
                        setSelectedBank(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label=""
                          id="bank_name"
                          sx={{
                            "& .MuiInputBase-input": {
                              height: "20px",
                              padding: "15px",
                            },
                          }}
                        />
                      )}
                    />
                  </Stack>
                </div>
                <label className="text-sm font-[600] text-[#344054]">
                  Account Number
                </label>
                <div className="w-full mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="account_number"
                    type={"number"}
                    form={form}
                    disabled={!selectedBank}
                    placeholder={"Enter your Account Number"}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />
                </div>
                <label className="text-sm font-[600] text-[#344054]">
                  Account Name
                </label>
                <div className="w-full relative mb-4">
                  <CustomInput
                    // width="400px"
                    fullWidth
                    height="18px"
                    id="account_name"
                    disabled={true}
                    form={form}
                    placeholder={"Account Name"}
                    borderColor="#D0D5DD"
                    mt="10px"
                    pt="28px"
                  />

                  {gettingAccountName ? (
                    <div className="w-full top-0 h-full absolute flex justify-center items-center ">
                      <img
                        width="30px"
                        height="30px"
                        alt="Button Icon"
                        src={"/Assets/loading2.gif"}
                      />
                    </div>
                  ) : null}
                </div>
              </>
            )}

            <div className="flex w-full  items-center">
              <CustomButton
                loading={form?.isSubmitting}
                variant="contained"
                customColor="#159AA8"
                children="Continue"
                width="100%"
                height="45px"
                disabled={
                  form?.values?.currency?.value === "USD"
                    ? false
                    : !form?.dirty || !selectedBank || !form?.isValid
                }
                // loading={loading}
                padding="10px"
                margin="15px 0"
                color="#fff"
                onClick={handleBankDetails}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default InitiateWithdrawal;
