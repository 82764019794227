import { createSlice } from "@reduxjs/toolkit";
import {
  addBankDetails,
  getUserBank,
  removeUserBank,
} from "../actions/bank/bankUserActions";
import { getBankList } from "../actions/userManagement/getBankList";

export const bankSlice = createSlice({
  name: "bank",
  initialState: {
    error: null,
    pending: false,
    isAddBank: false,
    success: false,
    banks: [],
    userBanks: [],
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(addBankDetails.pending, (state, action) => {
      state.isAddBank = true;
      state.success = false;
    });
    builder.addCase(addBankDetails.fulfilled, (state, action) => {
      state.isAddBank = false;
      state.success = true;
    });
    builder.addCase(addBankDetails.rejected, (state, action) => {
      state.isAddBank = false;
      state.success = false;
    });
    builder.addCase(getBankList.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getBankList.fulfilled, (state, action) => {
      state.pending = false;
      state.banks = action.payload;
    });
    builder.addCase(getBankList.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(getUserBank.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(getUserBank.fulfilled, (state, action) => {
      state.pending = false;
      state.userBanks = action.payload;
    });
    builder.addCase(getUserBank.rejected, (state, action) => {
      state.pending = false;
    });
    builder.addCase(removeUserBank.pending, (state, action) => {
      state.pending = true;
    });
    builder.addCase(removeUserBank.fulfilled, (state, action) => {
      state.pending = false;
      state.success = true;
    });
    builder.addCase(removeUserBank.rejected, (state, action) => {
      state.pending = false;
    });
  },
});

export default bankSlice.reducer;
