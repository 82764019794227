import React from "react";
import Button from "@mui/material/Button";

const CustomButton = ({
  children,
  variant,
  customColor,
  onClick,
  disabled,
  width,
  height,
  padding,
  loading,
  icon,
  margin,
  borderColor,
  iconWidth,
  maxWidth,
  minWidth,
  color,
  custumStyle,
  className,
  ...props
}) => {
  const buttonStyles = {
    backgroundColor: customColor,
    boxShadow: "none",
    opacity: disabled ? 0.6 : 1,
    width: width || "auto",
    height: height || "auto",
    padding: padding,
    margin: margin,
    borderRadius: 8,
    borderColor: borderColor,
    color: color,
    iconWidth: iconWidth,
    maxWidth: maxWidth || null,
    minWidth: minWidth || null,
    textTransform: "none",
    ...custumStyle,
  };

  return (
    <Button
      className={className}
      {...props}
      variant={variant}
      onClick={disabled ? null : onClick}
      style={buttonStyles}
    >
      {icon && (
        <span style={{ marginRight: "8px", width: iconWidth }}>{icon}</span>
      )}
      {loading ? (
        <img
          width="24px"
          height="24px"
          alt="Button Icon"
          src={"/Assets/loading.gif"}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
