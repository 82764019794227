import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";
import { useDispatch, useSelector } from "react-redux";
import InvestmentTable from "../../components/dashboard/investment/InvestmentTable";
import CustomButton from "../../components/Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductInvestment } from "../../store/actions/investment/getInvestments";
import { getAllPayout } from "../../store/actions/transactions/getAllPayout";
import useCurrency from "../../hooks/useCurrency";
import { getInvestmentMetrics } from "../../store/actions/transactions/getInvestmentMetrics";
import InvestmentCard from "../../components/investment/InvestmentCard";

const Businesses = () => {
  const dispatch = useDispatch();
  const { investmentMetrics } = useSelector((state) => state?.investment);

  const currency = useSelector((state) => state.dashboard.currency) || "NGR";

  const [_currency, getAmount] = useCurrency();

  const user = useSelector((state) => state.onboarding.user);

  useEffect(() => {
    dispatch(getAllProductInvestment());
    dispatch(getAllPayout());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getInvestmentMetrics());
  }, [currency]);

  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8">
        <div className="flex items-center mb-4 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-1" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-1" />

          <div className=" font-semibold text-[#475467] text-sm sm:text-base bg-[#F9FAFB] p-1 px-3 rounded-md  ">
            Business Management
          </div>
        </div>

        <div className="">
          <div className="flex flex-col md:flex-row justify-between items-center border-b border-b-[EAECF0] md:items-center mb-10">
            <div></div>

            {user?.roles &&
              !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
                <CustomButton
                  variant="contained"
                  customColor="#159AA8"
                  children="Create a New Business"
                  width="256px"
                  height="45px"
                  padding="10px"
                  margin="15px 0"
                  color="#fff"
                  onClick={() => navigate("/businesses/create")}
                />
              )}
          </div>

          <InvestmentCard
            // key={index}
            imageSrc={"opportunity?.banner_link"}
            // onClick={
            // opportunity?.investment_status == 0
            //   ? () => {
            //       // dispatch(
            //       //   showErrorModal(
            //       //     `Sorry, this investment has closed.`
            //       //   )
            //       // );
            //     }
            //   : () =>
            //       navigate(
            //         `/investment/business-financing/${opportunity.id}`
            //       )
            // }
            title={"opportunity?.name"}
            // text="Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
            location={"opportunity?.location"}
            campaign={"opportunity?.cycles?.length"}
            section1={
              "opportunity?.investment_category?.name" ||
              "opportunity?.category?.name"
            }
            section2={"opportunity?.sector"}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};
export default Businesses;
