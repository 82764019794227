import React from "react";

function MenubarIcon() {
  return (
    <div>
      <img
        src={"/Assets/menubar.png"}
        alt="Fund Icon"
        className={`w-6 h-6
         }`}
      />
    </div>
  );
}

export default MenubarIcon;
