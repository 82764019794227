const dateformatter = (date, type, withTime) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const _date = new Date(date);
  const _month = months[_date.getMonth()];
  const _day =
    date.getDate() > 3
      ? `${date.getDate()}th`
      : date.getDate() === 3
      ? "3rd"
      : date.getDate() === 2
      ? "2nd"
      : date.getDate() === 1
      ? "1st"
      : null;

  const _time = `${addzero(
    _date.getHours() % 12 === 0 ? 12 : _date.getHours() % 12
  )}:${addzero(_date.getMinutes())} ${_date.getHours() > 12 ? "PM" : "AM"}`;
  if (type === "formal") {
    let time = `${_month?.slice(
      0,
      3
    )} ${date.getDate()}, ${date.getFullYear()}`;
    if (withTime === true) {
      time = `${time}, ${_time}`;
    } else {
      time = `${time}`;
    }
    return time;
  } else {
    let time =
      new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
        date.getTime() / (1000 * 60 * 60).toFixed(1) <
      25
        ? "Today at " + _time
        : new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
            date.getTime() / (1000 * 60 * 60).toFixed(1) <
          49
        ? "Yesterday at " + _time
        : `${_day} ${_month} ${date.getFullYear()}`;
    if (withTime === true) {
      // time = `${time}, ${_time}`;
      time =
        new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
          date.getTime() / (1000 * 60 * 60).toFixed(1) <
        25
          ? "Today at " + _time
          : new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
              date.getTime() / (1000 * 60 * 60).toFixed(1) <
            49
          ? "Yesterday at " + _time
          : `${_day} ${_month} ${date.getFullYear()}, ${_time}`;
    } else {
      time = `${time}`;
    }
    return time;
  }
};

export const timeformatter = (rawDate, type, withTime, timeComesFirst) => {
  const date = new Date(rawDate);

  if (date == "Invalid Date") {
    return "";
  }
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const newDate = new Date();
  const newDateYear = new Date().getFullYear();
  const newDateMonth = new Date().getMonth();
  const newDateDate = new Date().getDate();

  const _date = new Date(date);
  const getMonth = _date.getMonth();
  const _month = months[getMonth];
  const getDate = _date.getDate();
  const getHours = _date.getHours();
  const getMinutes = _date.getMinutes();
  const getFullYear = _date.getFullYear();
  const getTime = _date.getTime();
  const getDateStr = _date.toDateString();

  const isDateWithInNextThreeDays =
    getFullYear === newDateYear &&
    getMonth === newDateMonth &&
    Math.abs(newDateDate - getDate) <= 3;
  const isTommorow =
    getFullYear === newDateYear &&
    getMonth === newDateMonth &&
    newDateDate + 1 === getDate;

  const _day =
    getDate > 3
      ? `${getDate}th`
      : getDate === 3
      ? "3rd"
      : getDate === 2
      ? "2nd"
      : getDate === 1
      ? "1st"
      : null;

  const _time = `${addzero(getHours % 12 === 0 ? 12 : getHours % 12)}:${addzero(
    getMinutes
  )} ${getHours > 12 ? "PM" : "AM"}`;
  const _time24 = `${addzero(getHours)}:${addzero(getMinutes)}`;

  if (type === "time_only") {
    return _time;
  } else if (type === "time24") {
    return _time24;
  } else if (type === "formal") {
    let time = `${_month?.slice(0, 3)} ${getDate}, ${getFullYear}`;
    if (withTime === true) {
      time = isTommorow
        ? timeComesFirst
          ? `${_time}, Tomorrow`
          : `Tomorrow, ${_time}`
        : timeComesFirst
        ? `${_time}, ${time}`
        : `${time}, ${_time}`;
    } else if (withTime === "withInThreeDays" && isDateWithInNextThreeDays) {
      time = isTommorow
        ? timeComesFirst
          ? `${_time}, Tomorrow`
          : `Tomorrow, ${_time}`
        : timeComesFirst
        ? `${_time}, ${time}`
        : `${time}, ${_time}`;
    } else {
      time = `${time}`;
    }
    return time;
  } else {
    let time =
      new Date() < _date
        ? `${_time}, ${_day} ${_month} ${getFullYear}`
        : new Date().toDateString() === getDateStr
        ? "Today at " + _time
        : isTommorow
        ? "Tomorrow at " + _time
        : new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
            getTime / (1000 * 60 * 60).toFixed(1) <=
          48
        ? "Yesterday at " + _time
        : `${_day} ${_month} ${getFullYear}`;
    if (withTime === true) {
      time = isTommorow
        ? "Tomorrow at " + _time
        : new Date().toDateString() === getDateStr
        ? "Today at " + _time
        : new Date().getTime() / (1000 * 60 * 60).toFixed(1) -
            getTime / (1000 * 60 * 60).toFixed(1) <=
          48
        ? "Yesterday at " + _time
        : timeComesFirst
        ? `${_time}, ${_day} ${_month} ${getFullYear}`
        : `${_time}, ${_day} ${_month} ${getFullYear}`;
    } else {
      time = `${time}`;
    }
    return time;
  }
};

const addzero = (number) => {
  return number < 10 ? `0${number}` : number;
};

export default dateformatter;
