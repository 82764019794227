//AUTH
export const API_REGISTER = "auth/register";
export const API_LOGIN = "auth/login";
export const API_FORGOT_PASSWORD = "auth/password/email";
export const API_VERIFY_OTP = "auth/otp_verify";
export const API_RESEND_OTP = "auth/resend_otp";
export const API_LOGOUT = "auth/logout";

//PROFILE
export const API_PROFILE = "profile/profile";
export const API_GET_INVESTMENT_AMOUNT = "profile/checkInvestmentAmount";
export const API_GET_USER_INVESTMENT = "profile/userInvestment";
export const API_GET_INVESTMENT_PORTFOLIO = "profile/userInvestmentPortfolio";
export const API_GET_INVESTMENT_SEARCHGROUP =
  "profile/userinvestmentSearchGroup";
export const API_GET_WITHDRAWALS = "profile/UserWithdrawals";
export const API_WITHDRAWAL_REQUEST = "profile/withdrawalRequest";

export const API_GET_OPPORTUNITIES = "investment/_product";
export const API_GET_OPPORTUNITY_DETAILS = "investment/_product/{id}";

export const API_UPDATE_PASSWORD = "update-password";
export const API_VERIFY = "auth/verify-email?userId={:id}&token={:token}";
export const API_GOOGLE_LOGIN = "auth/login/google";
export const API_GOOGLE_REGISTER = "auth/register/google";
export const API_RESEND_VERIFICATION = "auth/resend-token";
export const API_GET_USERS = "user";
export const API_LOGIN_PIN = "login-pin";
export const API_RESET_PIN = "customer/reset-pin";

export const API_RESET_PASSWORD = "auth/reset-password";

export const API_CREATE_PROFILE = "user/profile";

export const API_NOTIFICATION = "notification";
export const API_MARK_NOTIFICATION_AS_READ = "notification/mark-as-read";
export const API_DELETE_ALL_NOTIFICATION = "notification";
export const API_GET_READ_NOTIFICATIONS = "notification/read";
export const API_GET_UNREAD_NOTIFICATION = "notification/unread";

export const API_FUND_WALLET = "wallet/fundWallet";
export const GET_WALLET_BALANCE = "wallet/myWallet";
export const API_ADD_BANK_DETAILS = "profile/bank";
export const API_GET_BANK_NAMES = "profile/bank";
export const API_GET_BANKS = "all-banks";
export const API_GET_ACCOUNT_NAME = "verify-bank-account";
export const API_WITHDRAW_FUNDS = "Management/user/initiateWithdrawal";
export const API_GET_USER_BANK = "profile/bank";
export const API_REMOVE_BANK = "profile/bank";

export const API_UPDATE_USER_PROFILE = "profile/profile";
export const API_GET_USER_PROFILE = "profile/profile";

export const API_UPDATE_BVN = "profile/update-bvn";

export const API_GET_ALL_PACKAGES = "Management/subscription/packages";
export const API_RECORD_JOURNAL = "acct/record?stage=";
export const API_VERIFY_DOCUMENT =
  "Management/user/verifyUserDocument?user_id=";
export const API_USER_ROLES = "Management/user/role";
export const API_ASSIGN_USER_INTERNAL =
  "Management/user/internalStatus?user_id=";
export const API_ASSIGN_USER_ROLE = "Management/user/assign-role?user_id=";
export const API_REMOVE_USER_ROLE = "Management/user/remove-role?user_id=";

export const API_SUBSCRIBE_PACKAGE = "payment/start?subscriptionPackageId=";

// SUBSCRIPTION
export const API_CREATE_SUBSCRIPTION = "Management/subscription/packages";
export const API_GET_SUBSCRIPTION_BY_CURRENCY =
  "Management/user/subscription/packages/USD";

//INVESTMENT
export const API_CREATE_CATEGORY_INVESTMENT = "investment/_category";
export const API_UPDATE_CATEGORY_INVESTMENT = "investment/_category/";
export const API_GET_ALL_CATEGORY_INVESTMENT = "investment/_category";
export const API_GET_CATEGORY_BY_DETAIL = "investment/_category/";

export const API_CREATE_PRODUCT_INVESTMENT = "investment/_product";
export const API_UPDATE_PRODUCT_INVESTMENT = "investment/_product/";
export const API_UPDATE_USER_INVESTMENT = "investment/updateUserInvestment/";
export const API_GET_ALL_PRODUCT_INVESTMENT = "investment/_product";
export const API_GET_PRODUCT_BY_DETAIL = "investment/_product/";
export const API_GET_INVESTMENT_METRIC = "investment/investmentMetrics";
export const API_GET_INVESTMENT_METRIC_BY_CURRENCY =
  "investment/investmentMetricsByCurrency?currency=";
export const API_GET_TRANSACTION_METRIC_BY_CURRENCY =
  "investment/transactionMetricsByCurrency?currency=";
export const API_GET_PRODUCT_INTERESTS = "product/_interest";
export const API_GET_PAYMENT_SCHEDULES = "investment/payout";
export const API_GET_PAYOUT_HISTORY = "investment/payout_history";
export const API_TOGGLE_INVESTMENT = "investment/_product/status/";
export const API_TOGGLE_STATUS_INVESTMENT = "investment/_product/status/";
export const API_GET_PRODUCT_INVESTMENT_BY_CURRENCY = "investment/currency/";
export const API_GET_INVESTMENT_BY_INVESTOR =
  "investment/get-investor-by-investment-product-all/";
export const API_MOVE_INVESTMENT_UP =
  "investment/_product/move-up?investment_product_id=";
export const API_MOVE_INVESTMENT_DOWN =
  "investment/_product/move-down?investment_product_id=";
export const API_REODER_INVESTMENT = "investment/_product/re-order";

//user
export const API_GET_USER_METRICS = "Management/user/userMetrics";
export const API_GET_WAITING_USERS = "Management/user/waitingUsers";
export const API_GET_BIP_USERS = "Management/user/subscribers";
export const API_GET_BUSINESS_USERS = "admin/getUsers";
export const API_GET_ALL_USER = "Management/user/users";
export const API_GET_USER_DETAIL = "Management/user/users/";
export const API_GET_BUSINESS_USER_DETAIL = "admin/getUsers/";
export const API_GET_UPDATE_USER = "Management/user/users/";
export const API_DELETE_USER = "Management/user/destroyForce/";
export const API_RESET_USER_TRANSACTION_PIN =
  "Management/user/resetPin?user_id=";
export const API_GET_RESTORE_USER = "Management/user/restoreUser/";
export const API_GET_RESTORE_ALL_USERS = "Management/user/restoreAllUsers";
export const API_GET_RETRIEVE_ALL_TRASHED_USERS =
  "Management/user/retrieveAllTrashedUsers";
export const API_GET_RETRIEVE_ALL_USER_WITH_THRASHED =
  "Management/user/retrieveAllUsersWithTrashed";
export const API_USER_EMAIL_UPDATE = "Management/user/updateUserEmail/";
export const API_USER_ROLE_ASSIGN = "Management/user/assignRoleToUser";
export const API_GET_USER_SUBSCRIPTION_PACKAGE =
  "Management/user/get-user-subscriptions-using-subscription-package/";
export const API_GET_USER_PLAN = "Management/user/freePlan";
export const API_FUND_WALLET_OTP = "wallet/fund-otp?user_id=";
export const API_FUND_WALLET_CONFIRMATION = "wallet/credit-wallet?user_id=";
export const API_APPROVE_FREE_USER_PLAN =
  "Management/user/approveFreeSubscription?userId=";
export const API_SET_MASTER_PASSWORD = "Management/user/setMasterPass?user_id=";
export const API_REMOVE_MASTER_PASSWORD =
  "Management/user/removeMasterPass?user_id=";

//DISBURSEMENT
export const API_CREATE_DISBURSEMENT = "investment/disburse";
export const API_START_INVESTMENT = "investment/start-investment";
export const API_GET_DISBURSEMENT = "investment/getDisburse";
export const API_GET_DISBURSEMENT_LOG = "investment/view-disbursement-log";

export const API_MARK_ADMIN = "Management/user/assignRoleToUser";
export const API_ADD_PREVIOUS_RECORDS = "investment/updatePreviousRecord/";
export const API_ADD_BULK_PREVIOUS_RECORDS =
  "investment/updateBulkPreviousRecord";
export const API_CHANGE_MEMBERSHIP_PLAN =
  "Management/user/approveUserMemberShip/";
export const API_FETCH_USER_TRANSACTIONS =
  "Management/user/userTransactionHistories/";
export const API_FETCH_TRANSACTIONS =
  "transactions/get-total-transaction-by-currency?currency=";

export const API_FETCH_TRANSACTIONS_BY_CURRENCY =
  "transactions/transaction-history-by-currency?currency=";

export const API_FETCH_USER_SUBSCRIPTIONS =
  "Management/user/userSubscriptionHistories/";

export const API_SEND_EMAIL = "Management/user/sendEmail";
export const API_SAVE_TEMPLATE = "Management/template";
export const API_GET_ALL_TEMPLATES = "Management/template";
export const API_DELETE_TEMPLATE = "Management/template";

// Withdrawal Requests
export const API_FETCH_ALL_WITHDRAWAL_REQUESTS =
  "Management/user/withdrawalRequests";
export const API_GET_PAID_WITHDRAWAL_REQUESTS =
  "Management/user/withdrawalRequests/paid";
export const API_GET_PENDING_WITHDRAWAL_REQUESTS =
  "Management/user/withdrawalRequests/pending";
export const API_APPROVE_WITHDRAWAL_REQUESTS =
  "Management/user/approveWithdrawal?currency=";
export const API_CANCEL_WITHDRAWAL_REQUEST =
  "Management/user/cancelWithdrawalRequest?withdrawal_request_id=";

// Transactions
export const API_FETCH_TRANSACTION_BY_CURRENCY = "Wallet/transactionCurrency";
export const API_GET_BUSINESS_TRANSACTION = "admin/credit-transactions";
export const API_GET_APPLICATIONS = "admin/getAll";
export const API_GET_APPLICATION = "admin/get/";
export const API_UPDATE_APPLICATION_STATUS = "admin/credit-status/";

// Settlement
export const API_GET_ALL_SETTLEMENT = "settlement";
export const API_GET_SETTLEMENT_BY_ID = "settlement/user?user_id=";
