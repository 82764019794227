import { Menu } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { removeAdmin } from "../../store/actions/roles/removeAdmin";
import { getUserDetail } from "../../store/actions/userManagement/management";

const Usermenu = ({
  handleAdminUserClick,
  handleInternalUserClick,
  type,
  handleSetMasterPassword,
  handleRemoveMasterPassword,
  handleApproveUserClick,
  handleClose,
  handleResetPin,
  handleDeleteUserClick,
  initiateWithdrawal,
  initiateInvest,
  handleFundWalletClick,
  handleSubscriptionUserClick,
  anchorEl,
  masterPassword,
  selected,

  ...props
}) => {
  const user = useSelector((state) => state.onboarding.user);

  const dispatch = useDispatch();

  const [loadingRemove, setLoadingRemove] = useState(false);

  const handleRemoveAdminClicked = () => {
    setLoadingRemove(true);
    const data = {
      role: selected?.roles?.find((role) => role?.name.includes("Admin"))?.name,
    };
    dispatch(
      removeAdmin({
        data,
        id: selected?.id,
        setSubmitting: setLoadingRemove,
        cb: () => {
          dispatch(getUserDetail({ id: selected?.id }));
          setLoadingRemove(false);
        },
      })
    );
  };

  return (
    <Menu {...props}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "220px",
          paddingLeft: 5,
          paddingRight: 5,
          cursor: "pointer",
        }}
      >
        {user?.roles &&
          !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
            <span
              className=" font-[500] hover:bg-gray-100   p-3  hover:rounded-lg w-full"
              onClick={handleApproveUserClick}
            >
              Approve User
            </span>
          )}

        {user?.roles &&
          !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
            <span
              className="  font-[500] hover:bg-gray-100  p-3 hover:rounded-lg w-full"
              onClick={handleSubscriptionUserClick}
            >
              Make a Member
            </span>
          )}
        {user?.roles &&
          !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
            <span
              className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
              onClick={handleFundWalletClick}
            >
              Fund Wallet
            </span>
          )}
        {user?.roles &&
          (!user?.roles?.some((role) => role.name === "Super_Admin") ||
            true) && (
            <>
              <span
                className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
                onClick={initiateWithdrawal}
              >
                Initiate Withdrawal
              </span>
              <span
                className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
                onClick={initiateInvest}
              >
                Invest for User
              </span>
            </>
          )}
        {user?.roles &&
        type === "main" &&
        user?.roles?.some((role) => role.name === "Super_Admin") ? (
          selected?.roles?.find((role) => role?.name.includes("Admin")) ? (
            <>
              <span
                className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
                onClick={handleAdminUserClick}
              >
                Edit Role
              </span>
              <span
                className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
                onClick={loadingRemove ? null : handleRemoveAdminClicked}
              >
                {loadingRemove ? (
                  <div className="w-5 mx-auto h-5">
                    <img
                      width="24px"
                      height="24px"
                      alt="Button Icon"
                      src={"/Assets/loading2.gif"}
                    />
                  </div>
                ) : (
                  "Remove Admin Role"
                )}
              </span>
            </>
          ) : type === "main" &&
            !user?.roles?.some(
              (role) =>
                role.name === "Admin_Tier_1" || role.name === "Admin_Tier_2"
            ) ? (
            <span
              className="font-[500] hover:bg-gray-100 p-3 hover:rounded-lg w-full"
              onClick={handleAdminUserClick}
            >
              Make Admin
            </span>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        <span
          className="font-[500] hover:bg-gray-100 p-3 hover:rounded-lg w-full"
          onClick={handleInternalUserClick}
        >
          {!selected?.internal_user
            ? "Make Internal User"
            : "Remove Internal User"}
        </span>

        {user?.roles &&
          !user?.roles?.some(
            (role) =>
              role.name === "Admin_Tier_1" || role.name === "Admin_Tier_2"
          ) && (
            <span
              className=" font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
              onClick={handleResetPin}
            >
              Reset Pin
            </span>
          )}

        {user?.roles &&
          !user?.roles?.some(
            (role) =>
              role.name === "Admin_Tier_1" || role.name === "Admin_Tier_2"
          ) && (
            <>
              {masterPassword ? (
                <span
                  className="font-[500] hover:bg-gray-100 p-3 hover:rounded-lg w-full"
                  onClick={handleRemoveMasterPassword}
                >
                  Remove Master Password
                </span>
              ) : (
                <span
                  className="font-[500] hover:bg-gray-100 p-3 hover:rounded-lg w-full"
                  onClick={handleSetMasterPassword}
                >
                  Set Master Password
                </span>
              )}
            </>
          )}

        {user?.roles &&
          !user?.roles?.some(
            (role) =>
              role.name === "Admin_Tier_1" || role.name === "Admin_Tier_2"
          ) && (
            <span
              className="font-[500] hover:bg-gray-100   p-3 hover:rounded-lg w-full"
              onClick={handleDeleteUserClick}
            >
              Delete User
            </span>
          )}
      </div>
    </Menu>
  );
};

export default Usermenu;
