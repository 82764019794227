// messageSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const messageSlice = createSlice({
  name: "message",
  initialState: {
    loading: false,
    error: null,
    pending: false,
    success: false,
    modalText: "",
    isModalOpened: false,
  },

  reducers: {
    showErrorModal: (state, action) => {
      state.isModalOpened = true;
      state.modalText = action.payload;
      state.error = true;
    },
    showSuccessModal: (state, action) => {
      state.isModalOpened = true;
      state.modalText = action.payload;
      state.success = true;
    },
    closeModal: (state) => {
      state.isModalOpened = false;
      state.modalText = "";
      state.success = false;
    },
  },
});

export const { showModal, showErrorModal, showSuccessModal, closeModal } =
  messageSlice.actions;
export default messageSlice.reducer;
