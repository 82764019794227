import { Client } from "../../../client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { API_VERIFY_DOCUMENT } from "../../../config/api";

export const approveDocument = createAsyncThunk(
  "userProfile/approveDocument",
  async (payload, { dispatch }) => {
    try {
      const response = await Client({
        method: "PUT",
        path: API_VERIFY_DOCUMENT + payload.userId,
        data: payload.data,
      });
      console.log(response, "response");

      // dispatch(showSuccessModal("Profile updated"));
      toast.success("Document verified successfully");

      return response;
    } catch (error) {
      toast.error(error?.response?.data);
    }
  }
);
